import Bugsnag from '@bugsnag/js';
import { globalUser } from './store/user';
import { get } from 'svelte/store';
import { PUBLIC_ENV_TYPE } from '$env/static/public';

Bugsnag.start({
	apiKey: 'aa9f9d9def735b0b246eb0eec6ce950e',
	onError: function (event) {
		console.log('onError here ', get(globalUser), JSON.stringify(event.errors));
		event.setUser(get(globalUser)?.id, get(globalUser)?.email, get(globalUser)?.firstName);
	},
	releaseStage: PUBLIC_ENV_TYPE == 'localhost' ? 'development' : 'production',
	enabledReleaseStages: ['production', 'staging']
});


import * as Sentry from "@sentry/svelte";

Sentry.init({
	dsn: "https://f13d25ef4b0c621afa7ef4b8f6fd841b@o4506151575683072.ingest.sentry.io/4506390865313792",
	// release: "my-project-name@2.3.12",
	integrations: [new Sentry.BrowserTracing()],
  
	// Set tracesSampleRate to 1.0 to capture 100%
	// of transactions for performance monitoring.
	// We recommend adjusting this value in production
	tracesSampleRate: 1.0,
  });