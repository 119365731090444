import * as client_hooks from '../../../src/hooks.client.ts';

export { matchers } from './matchers.js';

export const nodes = [
	() => import('./nodes/0'),
	() => import('./nodes/1'),
	() => import('./nodes/2'),
	() => import('./nodes/3'),
	() => import('./nodes/4'),
	() => import('./nodes/5'),
	() => import('./nodes/6'),
	() => import('./nodes/7'),
	() => import('./nodes/8'),
	() => import('./nodes/9'),
	() => import('./nodes/10'),
	() => import('./nodes/11'),
	() => import('./nodes/12'),
	() => import('./nodes/13'),
	() => import('./nodes/14'),
	() => import('./nodes/15'),
	() => import('./nodes/16'),
	() => import('./nodes/17'),
	() => import('./nodes/18'),
	() => import('./nodes/19'),
	() => import('./nodes/20'),
	() => import('./nodes/21')
];

export const server_loads = [];

export const dictionary = {
		"/(admin)/(campaigns)": [5,[2]],
		"/(admin)/contributors": [11,[2]],
		"/(auth)/forgot-password": [17,[4]],
		"/(admin)/onboarding": [12,[2]],
		"/(admin)/ranking": [13,[2]],
		"/(auth)/reset-password": [18,[4]],
		"/(admin)/settings/payment-settings": [14,[2,3]],
		"/(admin)/settings/profile": [15,[2,3]],
		"/(admin)/settings/social-network": [16,[2,3]],
		"/(auth)/sigin": [19,[4]],
		"/(auth)/signup": [20,[4]],
		"/terms-of-use": [21],
		"/(admin)/(campaigns)/[id]": [6,[2]],
		"/(admin)/(campaigns)/[id]/pay": [7,[2]],
		"/(admin)/(campaigns)/[id]/pay/sucess": [8,[2]],
		"/(admin)/(campaigns)/[id]/upgrade": [9,[2]],
		"/(admin)/(campaigns)/[id]/upgrade/sucess": [10,[2]]
	};

export const hooks = {
	handleError: client_hooks.handleError || (({ error }) => { console.error(error) }),
};

export { default as root } from '../root.svelte';